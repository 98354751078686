body {
  background-color: #c3def8;
}

h2 {
  color: #343a40;
}

.container {
  max-width: 600px;
}
